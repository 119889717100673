import './index.scss';

import React, { ElementType, useCallback, useState } from 'react';

import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Collapse, Dialog as MuiDialog, DialogActions as MuiDialogActions,
    DialogActionsProps as MuiDialogActionsProps, DialogContent as MuiDialogContent,
    DialogContentProps as MuiDialogContentProps, DialogProps as MuiDialogProps,
    DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps
} from '@mui/material';
import { ContainedButton, OutlinedButton, TextButton } from '@orbit/button';
import { IconButton } from '@orbit/icon-button';
import {
    MainPalette, MainPalettes, MainThemeToken, MainThemeTokens, Typography, useOrbitTheme
} from '@orbit/theme-provider';

type ButtonType = 'confirm' | 'cancel';
type GuidanceSeverity = 'none' | 'standard' | 'critical';
export interface IActionGuidance {
    button: ButtonType;
    severity?: GuidanceSeverity
}
export interface IDialogActions {
    confirmText: string;
    onConfirmClick: () => void;
    cancelText?: string;
    onCancelClick?: () => void;
    actionGuidance?: IActionGuidance;
    disableConfirm?: boolean;
}

export type DialogProps = {
    title?: string;
    actions?: IDialogActions;
    message?: string;
    modal?: boolean;
    preventOutsideDismiss?: boolean;
    hideCloseButton?: boolean;
} & MuiDialogProps

interface IDialog extends MuiDialogProps {
    headerProps: DialogTitleProps,
    message: string,
    actions: IDialogActions,
}

type CloseReason = "escapeKeyDown" | "backdropClick"
const useDialog = ({ title, message, actions, modal = true, preventOutsideDismiss, hideCloseButton, onClose: onDialogClose, ...dialogProps }: DialogProps): IDialog => {
    const { paper: { background: backgroundColor } } = useOrbitTheme({ palette: MainPalettes.primary, token: MainThemeTokens.main })

    const headerProps: DialogTitleProps = {
        title,
        closeable: !!onDialogClose && !hideCloseButton,
        onClose: onDialogClose
    }

    const PaperProps = {
        sx: {
            backgroundColor,
            pointerEvents: 'auto'
        },
        elevation: modal ? undefined : 1
    }

    const dialogModalProps = modal ? null : {
        sx: {
            pointerEvents: 'none'
        },
        hideBackdrop: true,
        disableEnforceFocus: true
    };

    const onClose = useCallback((event, reason: CloseReason) => {
        if ((preventOutsideDismiss || !modal) && reason === 'backdropClick') {
            return;
        }
        if (onDialogClose) {
            onDialogClose(event, reason);
        }
    }, [preventOutsideDismiss, modal, onDialogClose])

    return {
        ...dialogProps,
        ...dialogModalProps,
        headerProps,
        message,
        actions,
        onClose,
        disableEscapeKeyDown: preventOutsideDismiss, // considering that if preventing clicking outside the dialog to close, that means Esc should also be disabled
        PaperProps
    }
}

export const Dialog = (props: DialogProps) => {
    const { headerProps, children, message, actions, ...dialogProps } = useDialog(props);

    return (
        <MuiDialog {...dialogProps}>
            <DialogTitle className='dialog-header' {...headerProps} />
            <DialogContent message={message} title={headerProps.title}>
                {children}
            </DialogContent>
            <DialogActions actions={actions} />
        </MuiDialog>
    )
}

export type DialogContentProps = {
    title?: string;
    message?: string;
} & MuiDialogContentProps;

export const DialogContent = ({ message, title, children, ...dialogContentProps }: DialogContentProps) => {
    const contentClassName = title ? dialogContentProps.className : `${dialogContentProps.className} no-title`;
    return (
        <MuiDialogContent className={contentClassName} {...dialogContentProps}>
            {message ?
                <Typography variant='body1' label={message} />
                : null}
            {children}
        </MuiDialogContent>
    )
}

type DialogActionsProps = {
    actions: IDialogActions;
} & MuiDialogActionsProps;

interface IDialogActionProps {
    button: ElementType,
    text?: string,
    palette: MainPalette,
    token: MainThemeToken,
    onClick: () => void,
    disabled?: boolean
}

interface IDialogActionsProps extends MuiDialogActionsProps {
    confirm: IDialogActionProps,
    cancel: IDialogActionProps,
}

const useDialogActions = ({ actions: { confirmText: confirm, onConfirmClick, cancelText: cancel, onCancelClick, disableConfirm, actionGuidance: { button, severity } }, ...actionProps }: DialogActionsProps): IDialogActionsProps => {
    const palette = severity === 'critical' ? MainPalettes.error : MainPalettes.primary;

    const ConfirmButton = confirm && severity !== 'none' && button === 'confirm' ? ContainedButton : (severity === 'none' ? TextButton : OutlinedButton);
    const CancelButton = cancel && severity !== 'none' && button === 'cancel' ? ContainedButton : (severity === 'none' ? TextButton : OutlinedButton);

    return {
        confirm: {
            button: ConfirmButton,
            text: confirm,
            palette: button === 'confirm' ? palette : MainPalettes.primary,
            token: MainThemeTokens.main,
            onClick: onConfirmClick,
            disabled: disableConfirm
        },
        cancel: {
            button: CancelButton,
            text: cancel,
            palette: button === 'cancel' ? palette : MainPalettes.primary,
            token: MainThemeTokens.main,
            onClick: onCancelClick
        },
        ...actionProps
    }
}

const DialogActions = (props: DialogActionsProps) => {
    if (!props.actions) {
        return null;
    }

    const { confirm: { button: ConfirmButton, text: confirmText, ...confirmProps }, cancel: { button: CancelButton, text: cancelText, ...cancelProps }, ...actionProps } = useDialogActions(props);

    return (
        <MuiDialogActions {...actionProps}>
            {!!cancelText &&
                <CancelButton {...cancelProps}>
                    {cancelText}
                </CancelButton>
            }
            <ConfirmButton {...confirmProps}>
                {confirmText}
            </ConfirmButton>
        </MuiDialogActions>
    )
}

type DialogTitleProps = {
    title?: string;
    collapsible?: boolean;
    closeable?: boolean;
    collapsed?: boolean;
    onClose?: (event?: {}, reason?: CloseReason) => void;
    onCollapse?: () => void;
} & MuiDialogTitleProps

const DialogTitle = ({ title, collapsed, collapsible, closeable, onClose, onCollapse, ...remainingProps }: DialogTitleProps) => {

    return (
        title ?
            <div className='dialog-header-container'>
                <MuiDialogTitle {...remainingProps}>
                    <div className='dialog-header-title-container'>
                        <Typography variant='h6' label={title} />
                    </div>
                    <div className='dialog-header-button-container'>
                        {collapsible ?
                            <IconButton
                                className='header-button'
                                palette={MainPalettes.primary}
                                token={MainThemeTokens.main}
                                icon={collapsed ? <ExpandMore /> : <ExpandLess />}
                                placement='right-end'
                                onClick={() => onCollapse()}
                            />
                            : null}
                        {closeable ?
                            <IconButton
                                className='header-button'
                                palette={MainPalettes.primary}
                                token={MainThemeTokens.main}
                                icon={<Close />}
                                placement='right-end'
                                onClick={() => onClose()}
                            />
                            : null}
                    </div>
                </MuiDialogTitle>
            </div>
            : null
    )
}

const useCollapsibleDialog = (props: DialogProps): IDialog => {
    const { headerProps, ...dialogProps } = useDialog({ ...props, modal: false });
    const { onClose: onCloseHeader } = headerProps
    const [collapsed, setCollapsed] = useState(false);

    const onCollapse = () => setCollapsed(!collapsed);
    const onClose = useCallback(() => {
        if (collapsed) {
            setCollapsed(!collapsed);
        }
        if (onCloseHeader) {
            onCloseHeader();
        }
    }, [collapsed, onCloseHeader])

    return {
        ...dialogProps,
        headerProps: { ...headerProps, collapsible: true, collapsed, onCollapse, onClose },
    }
}

export const CollapsibleDialog = (props: DialogProps) => {
    const { headerProps, message, children, actions, ...dialogProps } = useCollapsibleDialog(props);
    const { collapsed } = headerProps;
    if (!dialogProps.open) {
        return null;
    }
    return (
        <MuiDialog {...dialogProps}>
            <DialogTitle className='dialog-header' {...headerProps} />
            <Collapse in={!collapsed}>
                <DialogContent message={message} title={headerProps.title}>
                    {children}
                </DialogContent>
                <DialogActions actions={actions} />
            </Collapse>
        </MuiDialog>
    )
}